export default {
  namespaced: true,

  state: {
    commentsList: [],
  },

  getters: {
    commentsList: state => state.commentsList
  },

  mutations: {
    addComment(state, data){
        const foundComment = state.commentsList.findIndex(
            (item) => item.post_id === data.post_id
          );
        if (foundComment!=-1) {
            state.commentsList[foundComment].comments=data.comments;
            state.commentsList[foundComment].commentCount=data.commentCount;
            state.commentsList[foundComment].slice=data.slice;
        }
        else
        {
            state.commentsList.push(data)
        }
    },
    addMoreComment(state, data){
        const foundComment = state.commentsList.findIndex(
            (item) => item.post_id === data.post_id
          );
        if (foundComment!=-1) {
            state.commentsList[foundComment].comments.push(data.comment);
            state.commentsList[foundComment].commentCount=state.commentsList[foundComment].commentCount+1;
            state.commentsList[foundComment].slice=state.commentsList[foundComment].slice+1;
        }

    },
  },

  actions: {
    async setComment({ commit },data) {
        commit("addComment", data);
        },
    async setMoreComment({ commit },data) {
        commit("addMoreComment", data);
        }
    }
};
