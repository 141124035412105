import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import axios from "axios";

// Local
const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};
const tokenKey = process.env.VUE_APP_TOKEN_KEY;


initializeApp(firebaseConfig)
const messaging = getMessaging();

// Get tokens
getToken(messaging, { vapidKey: tokenKey })
  .then((currentToken) => {
    if (currentToken) {
      console.log('token', currentToken);
      axios
        .post(process.env.VUE_APP_API_URL + `saveToken?token=${currentToken}`)
        .then({});
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
      // ...
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
  });

// Get Messages
onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  // ...
});

export const firestoreDb = getFirestore()
export const realtimeDb = getDatabase()
export const storage = getStorage()
