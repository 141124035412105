export const ADD_TO_CART = (state, { product, quantity, size }) => {

    let productInCart = state.cart.find(item => {
        if (item.product.products.id === product.products.id && item.size === size) {
            return item.product.products.id === product.products.id;
        }
    });

    if (productInCart) {
        productInCart.quantity += quantity;
        return;
    }
    state.cart.push({
        product,
        quantity,
        size
    })
}

export const REMOVE_PRODUCT_FROM_CART = (state, { product, size }) => {
    let productInCart = state.cart.find(item => {
        if (item.product.products.id === product.products.id && item.size === size) {
            return item.product.products.id === product.products.id;
        }
    });

    state.cart = state.cart.filter(item => {
        // return item.product.products.id !== productInCart.product.products.id;
        return  item.size !== productInCart.size || item.product.products.id !== productInCart.product.products.id;
    })
}

export const CHECK_PRODUCT_EXISTS = (state, { product, size }) => {
    state.currentProduct = state.cart.find(item => {
        if (item.product.products.id === product.products.id && item.size === size) {
            return item.product.products.id === product.products.id;
        }
    })
}

// export const CHECK_PRODUCT_QUANTITY = (state, product) => {
//     state.cart = state.cart.filter(item => {
//         return item.product.products.id !== product.product.products.id;
//     })
// }

export const INCREMENT_TO_CART = (state, { product, size }) => {
    let productInCart = state.cart.find(item => {
        if (item.product.products.id === product.products.id && item.size === size) {
            return item.product.products.id === product.products.id;
        }
    });

    if (productInCart) {
        productInCart.quantity = productInCart.quantity + 1;
        return;
    }
}

export const DECREMENT_TO_CART = (state, { product, size }) => {

    let productInCart = state.cart.find(item => {
        if (item.product.products.id === product.products.id && item.size === size) {
            return item.product.products.id === product.products.id;
        }
    });

    if (productInCart) {
        if (productInCart.quantity - 1 === 0 ) {
            state.cart = state.cart.filter(item => {
                // if (item.product.products.id === product.products.id && item.size === size) {
                    // return item.product.products.id !== product.products.id;
                    return item.size !== productInCart.size || item.product.products.id !== productInCart.product.products.id;
                // }
            })
        }
        else {
            productInCart.quantity = productInCart.quantity - 1;
            return;
        }
    }
}

export const CLEAR_CART_ITEMS = (state) => {
    state.cart = [];
}

export const SET_CART = (state, cartItems) => {
    state.cart = cartItems;
}
export const SET_Tax_shipping = (state, configData) => {
    state.shopConfigData = configData;
}
