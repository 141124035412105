import axios from "axios";
export const addProductToCart = ({ commit }, { product, quantity, size }) => {
    commit('ADD_TO_CART', { product, quantity , size });
}

export const increaseQuantityFromCart = ({ commit }, product, size) => {
    commit('INCREMENT_TO_CART', product, size);
}

export const decreaseQuantityFromCart = ({ commit }, product, size) => {
    commit('DECREMENT_TO_CART', product, size);
}

export const removeProductFromCart = ({ commit }, product, size) => {
    commit('REMOVE_PRODUCT_FROM_CART', product, size);
}

export const checkProductExists = ({ commit }, product, size) => {
    commit('CHECK_PRODUCT_EXISTS', product, size);
}

export const clearCartItems = ({ commit }) => {
    commit('CLEAR_CART_ITEMS');
}
export const getTaxShippingData = ({ commit }) => {
    axios
        .get(process.env.VUE_APP_API_URL + "shop/fetchShopConfiguration", {
          headers: {
          },
        })
        .then((response) => {
          commit("SET_Tax_shipping", response.data.data);
        })
        .catch((error) => {
            console.log(error)
        });
}