const zeroPad = (num, pad) => {
	return String(num).padStart(pad, '0')
}

const isSameDay = (d1, d2) => {
	return (
		d1.getFullYear() === d2.getFullYear() &&
		d1.getMonth() === d2.getMonth() &&
		d1.getDate() === d2.getDate()
	)
}
 
export const parseTimestamp = (timestamp, format = '') => {
	if (!timestamp) return

	if(format != 'checkIfPast')
	{	
		const date = timestamp.seconds
		? new Date(timestamp.seconds * 1000)
		: timestamp	
		if (format === 'HH:mm') {
			// return `${zeroPad(date.getHours(), 2)}:${zeroPad(date.getMinutes(), 2)}`
			let hours = date.getHours() || 0;
			let cleanHours;
			if (hours === 0) {
			 cleanHours = 12;
			} else {
			 cleanHours = hours > 12 ? hours - 12 : hours; // else show proper am/pm -- todo: support 24hr time
			}
			let minutes = date.getMinutes();
			minutes = minutes >= 10 ? minutes : '0' + minutes.toString(); // turns 4 minutes into 04 minutes
			let ampm = hours >= 12 ? 'pm' : 'am'; // todo: support 24hr time
			 return `${cleanHours}:${minutes} ${ampm}`
		} 
		else if (format === 'messageFormat') {
			  const dateq = new Date();
				const option = { month: 'short', year: 'numeric', day: 'numeric' }
			  const today= `${new Intl.DateTimeFormat('en-US', option).format(dateq)}`
	
			  const options = { month: 'short', year: 'numeric', day: 'numeric' }
			  const finalDate= `${new Intl.DateTimeFormat('en-US', options).format(date)}`
	
			 if(today==finalDate)
			 {
				   return 'TODAY';
			 }
			   else
			{
				  return finalDate;
			}	
		} 
		else if (format === 'DD/MM/YY') {
			const options = { month: 'numeric', year: 'numeric', day: 'numeric' }
			return `${new Intl.DateTimeFormat('en-GB', options).format(date)}`
		}
		else if (format === 'MM DD,YYYY') {
			const options = { month: 'short', year: 'numeric', day: 'numeric' }
			return `${new Intl.DateTimeFormat('en-US', options).format(date)}`

		} else if (format === 'DD MMMM, HH:mm') {
			const options = { month: 'long', day: 'numeric' }
			return `${new Intl.DateTimeFormat('en-GB', options).format(
				date
			)}, ${zeroPad(date.getHours(), 2)}:${zeroPad(date.getMinutes(), 2)}`
		}
		else if (format === 'Yesterday') {
			return 'Yesterday';
		}
	}
	else
	{
		 const date = timestamp;
		 const dateFound=date.split("&&");

		 // Start Date
		 const startDateArray = dateFound[0].split("-");
		 const startYear = parseInt(startDateArray[0]);
		 const startMonth = parseInt(startDateArray[1]) - 1; // Adjust month to zero-based index
		 const startDay = parseInt(startDateArray[2]);
		 
		 // Get today's date without considering the time
		 const today = new Date();
		 today.setHours(0, 0, 0, 0);
		 
		 // Create a Date object for the event start date without considering the time
		 const eventStartDate = new Date(startYear, startMonth, startDay);
		 
		 if (eventStartDate < today) {
		   return 'past';
		 } else {
		   return 'upComing';
		 }
	}
}

export const formatTimestamp = (date, timestamp) => {

	const yesterday = new Date();
	yesterday.setDate(yesterday.getDate() - 1);
	var formatDate="MM DD,YYYY";


	if(yesterday.toDateString()==date.toDateString())
	{
		formatDate="Yesterday";
	}
	if(isSameDay(date, new Date()))
	
	{
		formatDate="HH:mm";
	}

	const timestampFormat =  formatDate;
	const result = parseTimestamp(timestamp, formatDate)
	return timestampFormat === 'HH:mm' ? ` ${result}` : result
}
