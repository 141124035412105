import * as firestoreService from "../database/firestore";
import { parseTimestamp } from "../utils/dates";
export default {
  namespaced: true,

  state: {
    notificationList: [],
    notificationCounter:0,
  },

  getters: {
    notificationList: state => state.notificationList,
    notificationCounter: state => state.notificationCounter,
  },

  mutations: {
    setNotificationData(state,list) {
      state.notificationList = list;
    },
    setNotificationCount(state,count) {
      state.notificationCounter = count;
    }
  },

  actions: {
    async createIndividualChatAction({ commit },dataUser) {
       console.log(commit);
       var sentUserId="";
       var sentUserCount = 0;

      //  otherUserId
       const promiseUser = await firestoreService.getUserCid(dataUser.selectedIndUsers.id.toString());
       sentUserId = promiseUser.data[0].id;
 
       // Check individual chat if exist
       
       const chatRoomsRef = await firestoreService.getExistsRoom(dataUser.currentUserId,sentUserId);
 
       // update counter
       const teamUserUncount=[];
       const checkCountSender = {
         id: sentUserId,
         count: (dataUser.individualMessage)?sentUserCount+1:sentUserCount,
       };
       teamUserUncount.push(checkCountSender);
 
       const checkCount = {
         id: dataUser.currentUserId,
         count: 0,
       };
       teamUserUncount.push(checkCount);

       // Create individual chat if not exist
       if (chatRoomsRef.length == 0) {
         const room = await firestoreService.addRoom({
           users: [sentUserId, dataUser.currentUserId],
           userCount: teamUserUncount,
           lastUpdated: new Date(),
           chatType: "individual",
           createdBy: dataUser.currentUserName,
           createdAt: parseTimestamp(new Date(), "DD/MM/YY"),
         });
         chatRoomsRef[0] = room.id;
       }
       // Send Message
       if (dataUser.individualMessage) {
         const message = {
           sender_id: dataUser.currentUserId,
           content: dataUser.individualMessage.trim(),
           timestamp: new Date(),
           pinFile: false,
           hasFile: false,
           tagsDetails: {
            name: "",
            tags: {},
            eventId: "",
            gameId: "",
          },
         };
         await firestoreService.addMessage(
          chatRoomsRef[0],
           message
         );

         await firestoreService.updateRoom(chatRoomsRef[0],{
          userCount: teamUserUncount,
          lastUpdated: new Date(),
          last_message_sent: dataUser.individualMessage.trim(),
          last_sender_id: dataUser.currentUserId,
        });
       }
      
       return true;
    },

    async sendMessageToTeam({ commit },dataUser) {
      console.log(commit);

      const message = {
        sender_id: dataUser.currentUserId,
        content:  dataUser.messageTeam.trim(),
        timestamp: new Date(),
        pinFile: false,
        hasFile: false,
        tagsDetails: {
         name: "",
         tags: {},
         eventId: "",
         gameId: "",
       },
      };
      await firestoreService.addMessage(
        dataUser.roomId,message
      );

      //  Update Count
      let teamUserUncount = [];
      dataUser.userCount.forEach((user) => {
        if (user.id != dataUser.currentUserId) {
          var oldcount = user.count === undefined ? 0 : user.count;
          var count = parseInt(oldcount) + 1;
          const checkCount = {
            id: user.id,
            count: count,
          };
          teamUserUncount.push(checkCount);
        } else {
          const checkCount = {
            id: user.id,
            count: 0,
          };
          teamUserUncount.push(checkCount);
        }
      });

      // Update Count End
      await firestoreService.updateRoom(dataUser.roomId, {
        userCount: teamUserUncount,
        last_message_sent: dataUser.messageTeam.trim(),
        last_sender_id: dataUser.currentUserId,
        lastUpdated: new Date(),
      });
    },
  }
};
