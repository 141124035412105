import axios from "axios";
import store from "../store";

export default {
  namespaced: true,

  state: {
    notificationList: [],
    notificationCounter:0,
  },

  getters: {
    notificationList: state => state.notificationList,
    notificationCounter: state => state.notificationCounter,
  },

  mutations: {
    setNotificationData(state,list) {
      state.notificationList = list;
    },
    setNotificationCount(state,count) {
      state.notificationCounter = count;
    },
    emptyNotification(state) {
      state.notificationList = [];
      state.notificationCounter = 0;
    },
  },

  actions: {
    async getUserNotificationData({ commit }) {
      return axios
        .post(process.env.VUE_APP_API_URL + "notification/listingTop")
        .then(response => {
          if (response.data.statusCode == 200) {
            commit("setNotificationData", response.data.data);
          }
          return response;
        });
    },
    async getUserNotificationDataPaginate({ commit }) {
      return axios
        .get(process.env.VUE_APP_API_URL + "notification/listing")
        .then(response => {
          if (response.data.statusCode == 200) {
            commit("setNotificationData", response.data.data.data);
          }
          return response;
        });
    },
    async getUserCheckedNotification({ commit }) {
      return axios
        .post(process.env.VUE_APP_API_URL + "notification/checkedCount")
        .then(response => {
          if (response.data.statusCode == 200) {
            commit("setNotificationCount", response.data.data);
          }
          return response;
        });
    },
    async setCounterCheck({ commit }) {
      return axios
        .post(process.env.VUE_APP_API_URL + "notification/checked")
        .then(response => {
          if (response.data.statusCode == 200) {
            commit("setNotificationCount", 0);
          }
          return response;
        });
    },
    async setCounterRead({ commit }, id) {
      console.log(commit);
      let readInNotificationList = store.state.notification.notificationList.find(item => {
        return item.id === id;
      });

      if (readInNotificationList) {
          readInNotificationList.read = 0;
      }
    
      return axios
        .post(process.env.VUE_APP_API_URL +`notification/read?id=${id}`)
        .then(response => {
          return response;
        });
    },
    async clearNotifications({ commit }) {
      commit('emptyNotification');
    },
  }
};
