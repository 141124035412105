import Vue from "vue";

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import { CollapsePlugin } from 'bootstrap-vue'

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// google fonts
import "../assets/fonts/opensans.css"


import "../assets/css/auth.css";
import "../assets/css/style.css";
import "../assets/css/Customization.css";
import "../assets/css/update.css";
import "../assets/css/responsive.css";

Vue.use(BootstrapVue);
Vue.use(CollapsePlugin)
Vue.use(BootstrapVueIcons);
