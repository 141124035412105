import axios from "axios";
// import * as firestoreService from "../database/firestore";


export default {
    namespaced: true,

    state: {
        eventData: null,
        calendarData: null,
        eventattend: null,
        eventDataScoreBox: null,
        eventDataScoreBoxAll: null,
    },

    getters: {
        event: state => state.eventData,
        calendar: state => state.calendarData,
        comingstatus: state => state.eventattend,
        eventScoreBox: state => state.eventDataScoreBox,
        eventScoreBoxAll: state => state.eventDataScoreBoxAll,
    },

    mutations: {
        setEventData(state, event) {
            state.eventData = event;
        },
        setEventDataScoreBox(state, event) {
            state.eventDataScoreBox = event;
        },
        setEventDataScoreBoxAll(state, event) {
            state.eventDataScoreBoxAll = event;
        },
        setCalendarData(state, calendar) {
            state.calendarData = calendar;
        },

        setEventAttend(state, comingstatus) {
            state.eventattend = comingstatus;
        },


    },

    actions: {
        setEventData({commit}, data) {
            commit("setEventData", data);
        },
        setEventDataScoreBox({commit}, data) {
            commit("setEventDataScoreBox", data);
        },
        setEventDataScoreBoxAll({commit}, data) {
            commit("setEventDataScoreBoxAll", data);
        },

        setEventAttend({commit}, data) {
            commit("setEventAttend", data);
        },

        setCalendarData({commit}, data) {
            commit("setCalendarData", data);
        },


        setEventListData({commit}, data) {
            var eventsDetails = [];
            this.showLoader = 1;
            const formData = new FormData();
            formData.append("sportsRoomID", data);

            axios.post(process.env.VUE_APP_API_URL + "event/calendereventattendance", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then(() => {

            axios.post(process.env.VUE_APP_API_URL + "event/calenderEventlist", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then((res) => {
                eventsDetails.push(...res.data.data.data);
                localStorage.setItem("ListlastPage", res.data.data.last_page);
                commit("setEventData", eventsDetails);
              });
            });

        },
        setEventListDataScoreBox({commit}, data) {
            var eventsDetails = [];
            this.showLoader = 1;
            const formData = new FormData();
            formData.append("sportsRoomID", data);

            axios.post(process.env.VUE_APP_API_URL + "event/scoreBoxEventList", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then((res) => {
                eventsDetails.push(...res.data.data.data);
                localStorage.setItem("ListlastPage", res.data.data.last_page);
                commit("setEventDataScoreBox", eventsDetails);
              });

        },
        setEventListDataScoreBoxAll({commit}, data) {
            var eventsDetails = [];
            this.showLoader = 1;
            const formData = new FormData();
            formData.append("sportsRoomID", data);

            axios.post(process.env.VUE_APP_API_URL + "event/scoreBoxEventListAll", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then((res) => {
                eventsDetails.push(...res.data.data.data);
                localStorage.setItem("ListlastPage", res.data.data.last_page);
                commit("setEventDataScoreBoxAll", eventsDetails);
              });

        },


        async getCurrentComingStatus({commit}, comingStatus) {

            let selectVisitEventStatus = [];
            let currentUserId = comingStatus.users;
            let usersDetails = comingStatus.usersDetail;

            var formData = new FormData();
            formData.append("sportsRoomID", comingStatus.teamID);
            await axios.post(
                process.env.VUE_APP_API_URL + "event/currentcomingstatus",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            ).then((response) => {

                const eventAttendeeFromDB = response.data.data;
                let tempMembers = [];
                usersDetails.forEach((teams) => {
                    tempMembers = teams.users;
                });

                for (const member of tempMembers) {
                    let Eventid;
                    let status;


                    for (const attendee of eventAttendeeFromDB) {
                        if (member.c_id == attendee.member_id) {
                              status=attendee.member_status;
                              Eventid=attendee.event_id;
                        }
                        if (member.id == currentUserId) {
                            selectVisitEventStatus[Eventid]=status;
                        }
                    }
                }
            });

            commit("setEventAttend", selectVisitEventStatus);
        },


    }
};
