<template>
  <div id="app">
    <main role="main">
      <router-view />
      <ChatBox/>

    </main>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ChatBox from './components/chatBot/ChatBox.vue';


export default {
  components: {
    ChatBox,
  },
    computed: {
    ...mapGetters("auth", ["user"]),
  },

  mounted() {
    if (localStorage.getItem("authToken")) {
      this.getUserData();
    }
  },

  methods: {
    ...mapActions("auth", ["getUserData"]),

    // logout() {
    //   this.sendLogoutRequest();
    //   window.location.reload();
    // },
  },
};
</script>